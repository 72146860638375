import React, { useState } from "react";
import { Axios } from "axios";
import { LeftFormContainer, LoadingContainer } from "../styles";
import { LoadingComponent } from "@kentarepo/kcf-assets";
import {
  ForgotPasswordStepOne,
  ForgotPasswordStepTwo,
  validEmail,
} from "./constants";

import {
  //stringFormat,
  userCustomerAuthenticationServices,
} from "@kentarepo/kcf-services";
import { deleteFromlocalStorage, getValueFromlocalStorage, insertTolocalStorage } from "../../forms/utils/sessionStorageEncrypt";

interface IBaseRestAPIResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  status: number;
}

export const ForgotPassword: React.FC<{
  api: Axios;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate?: any;
  navigateTo?: string;
  url?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addToast?: any
}> = ({ api, navigate, navigateTo, addToast }) => {
  const [loading, setLoading] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState<string>("");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!verifyNumberOfTries()) {
      return
    }

    const isValid = validEmail(email);

    if (!isValid) {
      addToast({
        type: "error",
        title: "Erro verificar o email",
        description: "Verifique se o email digitado é válido",
      });
      return;
    } else {
      setError(false);
      setLoading(true);

      const forgotPasswordExample = userCustomerAuthenticationServices(api);
      const { data, status }: IBaseRestAPIResponse =
        await forgotPasswordExample.forgotPassword({
          email: email,
          plataform: 0,
        });

      if (status === 200) {
        setStepTwo(true);
      } else {
        addToast({
          type: "error",
          title: "Erro",
          description: data || "Email inválido",
        });
        setExpirationTime();
      }

      setLoading(false);
    }
  };

  const setExpirationTime = () => {
    const exTimeValue = getValueFromlocalStorage("@Kenta:expirationTimeForgotPassword")
    if (exTimeValue) {
      // o tipo é {tries: number, time: string}
      const expirationTime = JSON.parse(exTimeValue);

      if (expirationTime.tries === 3) {
        return
      }

      expirationTime.tries = expirationTime.tries + 1;
      const currentDate = new Date();
      expirationTime.time = new Date(currentDate.getTime() + (60 * 60 * 1000));
      insertTolocalStorage("@Kenta:expirationTimeForgotPassword", JSON.stringify(expirationTime))
    } else {
      const expirationTime = {
        tries: 1,
        time: new Date().getTime() + 60 * 60 * 1000
      }
      insertTolocalStorage("@Kenta:expirationTimeForgotPassword", JSON.stringify(expirationTime))
    }
  };

  function verifyNumberOfTries() {
    const exTimeValue = getValueFromlocalStorage("@Kenta:expirationTimeForgotPassword")
    if (exTimeValue) {
      const expirationTime = JSON.parse(exTimeValue);
      if (expirationTime.tries === 3) {
        const storedTime = new Date(expirationTime.time);
        const currentTime = new Date();
        const isOneHourPassed = currentTime > storedTime;

        if (!isOneHourPassed) {
          addToast({
            type: "warning",
            title: "Numero de tentativas excedidas",
            description: "O numero de tentativas foi excedido, tente novamente em uma hora.",
          })
          return false;
        } else {
          deleteFromlocalStorage("@Kenta:expirationTimeForgotPassword")
        }
      }
    }
    return true;
  }

  return (
    <LeftFormContainer>
      {!stepTwo
        ? ForgotPasswordStepOne(
          email,
          setEmail,
          handleSubmit,
          error,
          setError,
          navigate
        )
        : ForgotPasswordStepTwo(navigate, navigateTo ?? "/")}
      {loading && (
        <LoadingContainer>
          <LoadingComponent loading={loading} />
        </LoadingContainer>
      )}
    </LeftFormContainer>
  );
};
