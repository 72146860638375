/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Container } from "./style";
import { CreateEditAttendant } from "./CreateEdit";
import { useToast } from "../../../hooks/toast";
import { AttendantService } from "../../../services/attendant";
import { getActualTime } from "../../../services/utils/dateTime";
import { AttendantRoleService } from "../../../services/attendantRole";
import { ContainerLeftMenu } from "../Administration/styles";
import { Administration } from "../Administration";
import { AdminArea } from "../../../components/Body/AdminArea";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { stringFormat } from "../../../utils/stringFormat";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { DrawerDefault } from "@kentarepo/kcf-assets";
import { LoadSpinner } from "../../../features/LoadSpinner";
import { LogOperation } from "../../../utils/entities/logOperation";

export interface AttendantInterface {
  id: number;
  customerId: number;
  attendantRoleId: number;
  active: boolean;
  name: string;
  foreignKey?: string;
  createIn: Date;
  updateIn: Date;
}

const Attendant: React.FC = () => {
  const [search, setSearch] = useState("");
  const [nameAttendant, setNameAttendant] = useState<string>("");
  const [active, setActive] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [element, setElement] = useState<AttendantInterface>();
  const [situation, setSituation] = useState<any>(2);
  const [listAttendant, setListAttendant] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [attendantRoleId, setAttendantRoleId] = useState<number>();
  const [attendantRoleName, setAttendantRoleName] = useState<string>("");
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [fieldErrorParticipant, setFieldErrorParticipant] =
    useState<boolean>(false);
  const user = getValueFromlocalStorage("@Kenta:user");
  const { addToast } = useToast();

  const filteredData =
    listAttendant?.filter((item: any) =>
      item?.name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
    ) ?? [];

  const clear = () => {
    setIsEdit(false);
    setNameAttendant("");
    setAttendantRoleName("");
    setAttendantRoleId(0);
    setActive(true);
    setOpenModal(false);
    setFieldError(false);
    setFieldErrorParticipant(false);
  };

  useEffect(() => {
    setFieldError(false);
  }, [nameAttendant, attendantRoleId]);

  const emptyName = stringFormat.isEmptyOrSpaces(nameAttendant);

  const getList = async () => {
    setLoading(true);
    let customerId: any = null;

    if (user) {
      const user_parsed = JSON.parse(user);

      customerId = user_parsed?.customerId;
    }
    try {
      const response = await AttendantService.getAllByCostumerId(
        customerId,
        situation
      );
      setListAttendant(response?.data?.data);
    } catch (error) {
      console.error("Erro ao carregar a lista de atendentes:", error);
    } finally {
      setLoading(false);
    }
  };

  const checkEditCreateAttendant = async () => {
    if (isEdit) {
      updateAttendant();
    } else {
      createAttendant();
    }
    await getList()
  };

  const createAttendant = async () => {
    let customerId: any = null;
    if (user) {
      const user_parsed = JSON.parse(user);
      customerId = user_parsed?.customerId;
    }
    const payload = {
      customerId: customerId,
      attendantRoleId: attendantRoleId,
      active: active,
      name: nameAttendant,
      createIn: getActualTime(),
      updateIn: getActualTime(),
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };

    if (emptyName || !attendantRoleId) {
      setFieldError(emptyName);
      addToast({
        type: "warning",
        title: "Participante",
        description:
          "Para criar um participante é necessário preencher os campos destacados",
      });

      setFieldErrorParticipant(!attendantRoleId);
    } else {
      try {
        setLoading(true);
        await AttendantService.postNewAttendant(payload);
        await getList()
        addToast({
          type: "success",
          title: "Participante cadastrado",
          description: "Participante foi cadastrado com sucesso.",
        });
      } catch (error) {
        addToast({
          type: "error",
          title: "Erro ao adicionar participante",
        });
      } finally {
        setLoading(false);
        getList();
        clear();
      }
    }
  };

  const updateAttendant = async () => {
    let customerId: any = null;
    if (user) {
      const user_parsed = JSON.parse(user);
      customerId = user_parsed?.customerId;
    }

    const payloadUpdate = {
      id: element?.id,
      customerId: customerId,
      attendantRoleId: attendantRoleId,
      active: active,
      name: nameAttendant,
      createIn: element?.createIn,
      updateIn: getActualTime(),
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
    if (emptyName || !attendantRoleId) {
      setFieldError(emptyName);
      addToast({
        type: "warning",
        title: "Participante",
        description:
          "Para criar um participante é necessário preencher os campos destacados",
      });
      setFieldErrorParticipant(!attendantRoleId);
    } else {
      try {
        setLoading(true);
        await AttendantService.putNewAtendant(payloadUpdate);
        addToast({
          type: "success",
          title: "Participante atualizado",
          description: "Participante atualizado com sucesso.",
        });
      } catch (error) {
        addToast({
          type: "error",
          title: "Erro ao editar participante",
        });
      } finally {
        setLoading(true);
        await getList();
        clear();
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getList();
    })()
  }, [situation]);

  async function getNameAttendantRole(attendatRoleId: number) {
    const selectRole = await AttendantRoleService.getById(attendatRoleId);
    setAttendantRoleName(selectRole.data.data.name);
  }

  const editAttendant = async (item: any) => {
    await getNameAttendantRole(item.attendantRoleId);
    setElement(item);
    setNameAttendant(item?.name);
    setActive(item.active);
    setAttendantRoleId(item.attendantRoleId);
    setIsEdit(true);
    setOpenModal(true);
  };
  const openModalCleared = () => {
    clear();
    setOpenModal(true);
  };

  return (
    <>
      <Container>
        <ContainerLeftMenu>
          <Administration />
        </ContainerLeftMenu>
        <AdminArea
          loading={loading}
          title={"Participantes"}
          InputSearchLabel="Buscar por"
          InputSearchOnChange={(text) => setSearch(text)}
          searchText={search}
          list={filteredData}
          headerAddButtonOnClick={() => openModalCleared()}
          onClick={(item: any) => editAttendant(item)}
          setOpenModal={setOpenModal}
          onChangeSituation={(value: any) => setSituation(value)}
          permissionName={FORMS_ADMIN.ATTENDANT}
          headerTrashButtonOnClick={function (): unknown {
            throw new Error("Function not implemented.");
          }}
        />
        <DrawerDefault
          openDrawer={openModal}
          setOpenDrawer={setOpenModal}
          title={isEdit ? "Editar participante " : "Criar participante "}
          onClose={() => setOpenModal(false)}
          onApply={() => checkEditCreateAttendant()}
          children={
            <CreateEditAttendant
              onChangeName={setNameAttendant}
              valueEdit={nameAttendant}
              active={active}
              onChangeSwitch={() => setActive(!active)}
              setAttendantRoleDropSelected={setAttendantRoleId}
              attendantRoleName={attendantRoleName}
              fieldError={fieldError}
              fieldErrorParticipant={fieldErrorParticipant}
            />
          }
        />
      </Container>
      <LoadSpinner loading={false} />
    </>

  );
};

export default Attendant;
