/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, Suspense } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";

import { colors, VerticalHr, NotificationIcon } from "@kentarepo/kcf-assets";

import { IHeader } from "./interface";
import { profileServices } from "@kentarepo/kcf-services";
import {
  BoxMenuLeftFullScrean,
  DivLogo,
  Logo,
  Container,
  ButtonTabGroupFixed,
} from "./styles";

import { SessionLocalStorage } from "@kentarepo/kcf-templates";
import ProfileModal from "../../components/ProfileModal";
import { getValueFromlocalStorage } from "../../services/utils/sessionStorageEncrypt";
import { UserCustomerProfile } from "../../services/user_customer_profile/types";
import { UserCustomerProfileService } from "../../services/user_customer_profile";
import { PictureContainer, avatarStyle } from "../../components/ProfileModal/style";
import Avatar from "react-avatar";

import { storageBaseUrl } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setContainerName } from "../../store/modules/profile/actions";
import { insertTolocalStorage } from "../../utils/sessionStorageEncrypt";
import { Link, useNavigate } from "react-router-dom";
import { ProfileMenu } from "../../features/ProfileMenu";
import { IProfile } from "../../features/ProfileMenu/interface";
import CloundDownloadIcon from "../../assets/icons/cloundDownloadIcon";
import DashboardIcon from "../../assets/icons/dashboardIcon";
import { usePermissions } from "../../hooks/permission";
import { FORMS_HEARING } from "../../utils/entities/permission";
import { Tooltip } from "@mui/material";

interface User {
  id: number;
  picture?: string;
  name: string;
  customerId: number;
}

interface IHeaderCustom extends IHeader {
  qntNotifications: number;
  onClickNotifications: () => unknown;
  onClickDownloadApp: () => unknown;
  menuIndexToShow: number | undefined;
  setMenuIndexToShow: (index: number) => any;
}

export const Header: React.FC<IHeaderCustom> = ({
  id,
  logo,
  menus,
  api,
  menuIndexToShow,
  setSelectedProfile,
  qntNotifications,
  onClickNotifications,
  onClickDownloadApp,
}) => {
  const [load, setLoad] = useState<boolean>(false);
  const [isOpenSideModal, setIsOpenSideModal] = useState(false);
  const [profileList, setProfileList] = useState<IProfile[]>([]);
  const userStorage = getValueFromlocalStorage("@Kenta:user");

  const [user, setUser] = useState<User | null>();
  const [userCustomerProfiles, setUserCustomerProfiles] = useState<
    UserCustomerProfile[]
  >([]);

  const [changePicture, setChangePicture] = useState<number>(0);
  const [containerNameStage, setContainerNameStage] = useState<string>("");
  const [avatarUrl, setAvatarUrl] = useState<string>("");

  const { userCustomerProfile } = useSelector((state: any) => state.profile);

  const { export: exportDownloads } = usePermissions(FORMS_HEARING.HEARING_FILE);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  async function profileServicesExemple() {
    const apiProfileService = profileServices(api);
    const userCustomerIdLocalStorage = SessionLocalStorage.getUserCustomerId();

    if (userCustomerIdLocalStorage) {
      const { data, success } =
        await apiProfileService.getUserCustomerProfileByPlace({
          customerId: userCustomerIdLocalStorage,
        });

      if (success) {
        insertTolocalStorage("@Kenta:profiles", JSON.stringify(data));
        return data;
      }
    }
  }

  const menuList =
    userCustomerProfile?.profile === "Operador"
      ? menus.filter((menu) =>
          menu.rolesToShow.includes(userCustomerProfile?.profile)
        )
      : menus;  

  useEffect(() => {
    setLoad(true);
    const fetchData = async () => {
      const profilesList = await profileServicesExemple();
      const existsMaster = profilesList.find((x: any) => x.profile.toLowerCase() === "master")
      const filterProfiles = profilesList.filter((x: any) => x.profile.toLowerCase() !== "master")

      setProfileList([existsMaster, ...filterProfiles]);
    };
    fetchData();

    setLoad(false);
  }, []);

  const searchProfiles = async () => {
    const token = getValueFromlocalStorage("@Kenta:access_Token");

    if (!userStorage) {
      return;
    }

    if (!token || !user || !user?.customerId) {
      return;
    }

    const response = await UserCustomerProfileService.getAllByUserCustomer(
      token,
      user?.id
    );

    if (!response.data || !response.status) return;
    setUserCustomerProfiles(response.data);

    dispatch(setContainerName(response?.data[0]?.containerName?.toString()));
    const storageBase = `${storageBaseUrl}/${response?.data[0]?.containerName}/${user.picture}`;
    setContainerNameStage(response?.data[0]?.containerName);
    setAvatarUrl(storageBase);
  };

  useEffect(() => {
    searchProfiles();
  }, [user]);

  useEffect(() => {
    if (userStorage) {
      const userStorageImage = JSON.parse(userStorage);

      userStorageImage.picture = userStorageImage.picture
        ? userStorageImage.picture.replace(/\\/gm, "/")
        : "";

      setUser(userStorageImage);
    }
  }, []);

  useEffect(() => {
    //Change picture
    if (changePicture > 0) {
      const user = getValueFromlocalStorage("@Kenta:user");
      const parsedUser = user ? JSON.parse(user) : null;

      const containerNameAux = containerNameStage ?? "riograndedosul";

      const storageBase = `${storageBaseUrl}/${containerNameAux}/${parsedUser.picture}`;
      setAvatarUrl(storageBase);
    }
    setChangePicture(0);
  }, [changePicture]);

  const handleSelectProfile = (profile: any) => {
    setSelectedProfile(profile);
  };

  const handleOpenProfileModal = () => {
    setIsOpenSideModal(true);
  };
  const mobileMenuId = "primary-search-account-menu-mobile";

  return !load ? (
    <Container>
      <Suspense fallback={<div>Loading...</div>}>
        <Box id={id} sx={{ flexGrow: 1, display: "flex", height: "60px" }}>
          <AppBar
            position="static"
            sx={{
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
              width: "100%",
              height: "60px",
              background: colors.default_base_white,
              boxShadow: "none",
            }}
          >
            <Toolbar disableGutters>
              {/* PARTE DA ESQUERDAAAAAAAAAA */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "start",
                  gap: "24px",
                }}
              >
                <BoxMenuLeftFullScrean>
                  <DivLogo>
                    <Logo src={logo} />
                  </DivLogo>
                  <VerticalHr height="40px" />
                  <ButtonTabGroupFixed
                    menuList={menuList}
                    indexToSelect={menuIndexToShow}
                    navigate={navigate}
                  />
                </BoxMenuLeftFullScrean>
              </Box>
              <Box sx={{ display: "inline-flex", m: 1, p: 1 }}>
                <Box
                  sx={{
                    width: "420px!important",
                    paddingRight: "24px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <ProfileMenu
                    profiles={profileList}
                    selected={userCustomerProfile}
                    handleSelectProfile={handleSelectProfile}
                  />
                </Box>

                <Box
                  sx={{
                    width: "24px",
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                    alignContent: "center",
                  }}
                >
                  <VerticalHr height="40px" />
                </Box>
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={() => { }}
                    color={"default"}
                  >
                    <MoreIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                    alignContent: "center",
                    cursor: "pointer",
                    width: "60px",
                    height: "40px",
                  }}
                >
                  <Tooltip title="Dashboard">
                  <Box sx={{ marginTop: "2px" }}>
                    <Link to="/dashboard">
                      <DashboardIcon />
                    </Link>
                  </Box>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                    alignContent: "center",
                    cursor: "pointer",
                    width: "50px",
                    height: "40px",
                  }}
                >
                  <Tooltip title="Notificações">
                    <Box sx={{ marginLeft: "-40px" }}>
                      <NotificationIcon
                        qtdNotification={qntNotifications}
                        tooltiTitle=""
                        onClick={onClickNotifications}
                      />
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                    alignContent: "center",
                    cursor: "pointer",
                    width: "70px",
                    height: "40px",
                  }}
                >
                   <Tooltip title="download">
                  <Box
                    sx={{
                      marginRight: "20px",
                      marginLeft: "-20px",
                      marginTop: "5px",
                    }}
                    onClick={onClickDownloadApp}
                  >
                    {exportDownloads ? <CloundDownloadIcon /> : null}
                  </Box>
                  </Tooltip>
                  <VerticalHr height="40px" />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                    alignContent: "center",
                    cursor: "pointer",
                    width: "38px",
                    height: "38px",
                  }}
                  id="avatar_container"
                  onClick={handleOpenProfileModal}
                >
                  {userCustomerProfiles && user && user.picture ? (
                    <PictureContainer size={38} picture={avatarUrl} />
                  ) : (
                    <Avatar
                      name={user && user.name ? user.name : "user test"}
                      size="38"
                      round={true}
                      style={{ ...avatarStyle }}
                    />
                  )}
                </Box>

                {userCustomerProfiles &&
                  userCustomerProfiles.length > 0 &&
                  user ? (
                  <ProfileModal
                    isOpen={isOpenSideModal}
                    onClose={() => setIsOpenSideModal(false)}
                    profile={userCustomerProfiles[0].profile}
                    user={user}
                    name={user && user.name ? user.name : "user test"}
                    changePicture={changePicture}
                    setChangePicture={setChangePicture}
                    containerName={containerNameStage}
                    setContainerName={setContainerNameStage}
                  />
                ) : null}
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Suspense>
    </Container>
  ) : null;
};
