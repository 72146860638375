/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from "react";
import { InputGeneral } from "../../../../components/Input";
import {
  Container,
  ContainerBody,
  ContainerInputs,
} from "../../StyleCSS/bodyCreateEdit";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { HearingRoleService } from "../../../../services/hearingRole";
import { getValueFromlocalStorage } from "../../../../utils/sessionStorageEncrypt";
import { CheckBoxConfidential } from "../../../../components/CheckBoxConfidetial";
import { DateInput, TimeInput } from "@kentarepo/kcf-assets";

type IErrorsFields = {
  nameAudience: boolean;
  hours: boolean;
  date: boolean;
  audienceType: boolean;
};

interface ICreateAudience {
  valueEdit?: string;
  onChangeName: (e: string) => unknown;
  onChangeHours: (e: number) => unknown;
  onChangeDate: (e: string) => unknown;
  active: boolean;
  onChangeSwitch?: (e: boolean) => unknown;
  errorFields: IErrorsFields;
  date: any;
  time: any;
  isConfidential: boolean;
  setIsConfidential: (isConfidential: boolean) => unknown;
  setProcedureTypeId: (e: number) => unknown;
  procedureNumber?: string;
  editTypeSelected?: any;
  setObjectType?: any;
  audience?: any;
  createAudienceMask: string;
  isEdit:boolean
}

const CreateAudience = (props: ICreateAudience) => {
  const [audienceTypeList, setAudienceTypeList] = useState<any>([]);
  const [processValue, setProcessValue] = useState(props.procedureNumber||'')
  const user = getValueFromlocalStorage("@Kenta:user");

function applyMask(input: string, pattern: string) {
  let i = 0;
  let formattedValue = "";
  let value = props.isEdit && input ? input.replace(/[.,/-]/g, '') : input;
  
  for (let p = 0; p < pattern.length; p++) {
    if (pattern[p] === "\\" && pattern[p + 1] === "d") {
      if (i < value.length) {
        formattedValue += value[i];
        i++;
      } else {
        break;
      }
      p++;
    } else {
      if (i < value.length) {
        formattedValue += pattern[p];
      }
    }
  }
  props.onChangeName(formattedValue.replace(/\\/g, ""));
  return formattedValue.replace(/\\/g, "");
}

  useEffect(() => {
    let customerId: any = null;
    (async () => {
      if (user) {
        const user_parsed = JSON.parse(user);
        customerId = user_parsed?.customerId;
        const response = await HearingRoleService.getAll(customerId, 1);

        const getHearingType = response?.data?.data?.find(
          (type: any) => type?.id === props?.audience?.hearingTypeId
        );

        props.setObjectType(getHearingType);
        const onlyActives = response?.data?.data?.filter((e: any) => e.active);
        setAudienceTypeList(onlyActives);
      }
    })();
  }, [props.audience]);

  const getSelected = (typeObject: any) => {
    props.setProcedureTypeId(typeObject?.id);
    props.setObjectType(typeObject);
  };

  const onChangeProcessValue = (e: string) => {
    const unmaskedValue = e.replace(/\D/g, "");
    setProcessValue(unmaskedValue)
    return props.onChangeName(unmaskedValue);
  };
  const onChangeProcessValueDefault = (e: string) => {
    const partner = /[\d\.\/\-\'']/gi

    if (!!partner.test(e[e.length - 1]) || e == "") {
      return props.onChangeName(e)
    }
  }

  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerInputs>
            <InputGeneral
              error={!props.procedureNumber && props.errorFields.nameAudience}
              value={props.createAudienceMask ? applyMask(processValue, props.createAudienceMask) : props.procedureNumber}
              onChange={props.createAudienceMask ? onChangeProcessValue : onChangeProcessValueDefault}
              label="Número do processo"
              maxLength={200}
              required={true}
            />
          </ContainerInputs>
          <ContainerInputs>
            <DateInput
              label={"Data/Hora audiência"}
              setValue={(e: any) => props.onChangeDate(e)}
              value={props.date}
              required={true}
              error={props.errorFields.date}
            />
            <div style={{ paddingTop: "20px", width: "400px" }}>
              <TimeInput
                label={""}
                setValue={(e: any) => props.onChangeHours(e)}
                error={props.errorFields.date}
                required={false}
                value={props.time}
              />
            </div>
          </ContainerInputs>
          <ContainerInputs>
            <DropdownGlobal
              error={props.errorFields.audienceType}
              editDropdown={props.editTypeSelected}
              labelName="Tipos"
              options={audienceTypeList}
              required={true}
              setSelected={getSelected}
              wantAnObject={true}
            />
          </ContainerInputs>

          <ContainerInputs>
            <CheckBoxConfidential
              value={props.isConfidential}
              label="Confidencial"
              checked={() => props.setIsConfidential(!props.isConfidential)}
            />
          </ContainerInputs>
        </ContainerBody>
      </Container>
    </>
  );
};

export default CreateAudience;
