import styled from "styled-components";

interface BoxProps {
  alignItems?: string;
  marginTop?: string;
  marginBottom?: string;
}

export const ContainerWaterMaker = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyWaterMark = styled.div`
  display: block;
  width: 100%;

  span {
    font-size: 14px;
    font-family: "Inter";
  }
`;

export const ContainerSelectTypeMark = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;

export const Box = styled.div<BoxProps>`
  width: 173px;
  height: 90px;
  border: 2px solid #c2c2c2;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.alignItems || 'flex-start'};
  }

  input {
    width: 20px;
    height: 20px;
  }

  span {
    margin-top: ${(props) => props.marginTop || '0'};
    margin-bottom: ${(props) => props.marginBottom || '0'};
    font-family: "Inter";
    font-size: 12px;
  }
`;


export const ContainerCheckboxList = styled.div`
  margin-top: 30px;

  div + div {
    margin-top: 12px;
  }
`;