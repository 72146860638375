import { useEffect, useState } from "react";
import { AdminArea } from "../../../components/Body/AdminArea";
import { UnitService } from "../../../services/place";
import { stringFormat } from "../../../utils/stringFormat";
import { CreateEditUnit } from "./CreateEdit";
import { LogOperation } from "../../../utils/entities/logOperation";

//Toast
import { useDispatch } from "react-redux";
import { useToast } from "../../../hooks/toast";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";

//Permissões
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { ContainerLeftMenu } from "../Administration/styles";
import { Administration } from "../Administration";
import { Container } from "./styles";
import { DrawerDefault } from "@kentarepo/kcf-assets";

export interface UnitInterface {
  id: number;
  foreignKey: string;
  name: string;
  description: string;
  city: string;
  address: string;
  addressNumber: number;
  phone: string;
  district: number;
  codeDistrict: number;
  customerId: number;
  lat: number;
  lon: number;
  extraContent: string;
  createIn: Date;
  updateIn: Date;
  active: boolean;
  type: number;
}

export function Unit() {
  const [element, setElement] = useState<UnitInterface>();
  const [name, setName] = useState("");
  const [active, setActive] = useState(true);
  const [description, setDescription] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [addressNumber, setAddressNumber] = useState<number>();
  const [phone, setPhone] = useState("");
  const [unit, setUnit] = useState(false);
  const [codeDistrict, setCodeDistrict] = useState(0);
  const [loading, setLoading] = useState(true);
  const [extraContent, setExtraContent] = useState("");
  const [latLng, setLatLng] = useState("");
  const [foreignKey, setForeignKey] = useState("");
  const [listUnit, setListUnit] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const user = getValueFromlocalStorage("@Kenta:user");
  const [search, setSearch] = useState("");
  const [situation, setSituation] = useState<number | null>(2);
  const [fieldErrorName, setFieldErrorName] = useState(false);
  const [fieldErrorDescription, setFieldErrorDescription] = useState(false);
  const [fieldErrorCity, setFieldErrorCity] = useState(false);
  const [fieldErrorAddress, setFieldErrorAddress] = useState(false);
  const [fieldErrorAddressNumber, setFieldErrorAddressNumber] = useState(false);
  const [fieldErrorPhone, setFieldErrorPhone] = useState(false);
  const [fieldErrorForeignKey, setFieldErrorForeignKey] = useState(false);
  const [fieldErrorExtraContent, setFieldErrorExtraContent] = useState(false);
  const [fieldErrorCodeDistrict, setFieldErrorCodeDistrict] = useState(false);

  function validateFormFields() {
    let nameValide: boolean = false;
    let descriptionValide: boolean = false;
    let cityValide: boolean = false;
    let addressValide: boolean = false;
    let addressNumberValide: boolean = false;
    let phoneValide: boolean = false;
    let extraContentValide: boolean = false;
    let foreignKeyValide: boolean = false;
    let codeDistrictValide: boolean = false;

    if (!name || stringFormat.isEmptyOrSpaces(name)) {
      setFieldErrorName(true);
      nameValide = false;
    } else {
      setFieldErrorName(false);
      nameValide = true;
    }

    if (!description || stringFormat.isEmptyOrSpaces(description)) {
      setFieldErrorDescription(true);
      descriptionValide = false;
    } else {
      setFieldErrorDescription(false);
      descriptionValide = true;
    }

    if (!city || stringFormat.isEmptyOrSpaces(city)) {
      setFieldErrorCity(true);
      cityValide = false;
    } else {
      setFieldErrorCity(false);
      cityValide = true;
    }

    if (!address || stringFormat.isEmptyOrSpaces(address)) {
      setFieldErrorAddress(true);
      addressValide = false;
    } else {
      setFieldErrorAddress(false);
      addressValide = true;
    }

    if (!addressNumber || addressNumber < 0) {
      setFieldErrorAddressNumber(true);
      addressNumberValide = false;
    } else {
      setFieldErrorAddressNumber(false);
      addressNumberValide = true;
    }

    if (!phone || stringFormat.isEmptyOrSpaces(phone)) {
      setFieldErrorPhone(true);
      phoneValide = false;
    } else {
      setFieldErrorPhone(false);
      phoneValide = true;
    }

    if (!extraContent || stringFormat.isEmptyOrSpaces(extraContent)) {
      setFieldErrorExtraContent(true);
      extraContentValide = false;
    } else {
      setFieldErrorExtraContent(false);
      extraContentValide = true;
    }

    if (!foreignKey || stringFormat.isEmptyOrSpaces(foreignKey)) {
      setFieldErrorForeignKey(true);
      foreignKeyValide = false;
    } else {
      setFieldErrorForeignKey(false);
      foreignKeyValide = true;
    }

    if (!unit && (!codeDistrict || codeDistrict === 0)) {
      setFieldErrorCodeDistrict(true);
      codeDistrictValide = false;
    } else {
      setFieldErrorCodeDistrict(false);
      codeDistrictValide = true;
    }

    if (
      nameValide === false ||
      descriptionValide === false ||
      cityValide === false ||
      addressValide === false ||
      addressNumberValide === false ||
      phoneValide === false ||
      extraContentValide === false ||
      foreignKeyValide === false ||
      codeDistrictValide === false
    ) {
      return false;
    } else {
      return true;
    }
  }

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  useEffect(() => {
    onLoadList();
  }, [situation]);

  async function onLoadList() {
    setLoading(true);
    dispatch(showLoading());
    const response = await UnitService.getAll(customerId, situation);
    if (response) {
      if (response.status) {
        setListUnit(response.data?.data);
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }
    setLoading(false);
    dispatch(hideLoading());
    return [];
  }

  async function clear() {
    setIsEdit(false);
    setName("");
    setActive(true);
    setElement(undefined);
    setDescription("");
    setCity("");
    setAddress("");
    setAddressNumber(undefined);
    setPhone("");
    setUnit(true);
    setCodeDistrict(0);
    setExtraContent("");
    setLatLng("");
    setForeignKey("");
    setOpenModal(false);
    setFieldErrorName(false);
    setFieldErrorDescription(false);
    setFieldErrorCity(false);
    setFieldErrorAddress(false);
    setFieldErrorAddressNumber(false);
    setFieldErrorPhone(false);
    setFieldErrorForeignKey(false);
    setFieldErrorExtraContent(false);
    setFieldErrorCodeDistrict(false);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  async function onClick(item: UnitInterface) {
    setIsEdit(true);
    setName(item.name);
    setActive(item.active);
    setDescription(item.description);
    setCity(item.city);
    setAddress(item.address);
    setAddressNumber(item.addressNumber);
    setPhone(item.phone);
    setUnit(item.district === 0 ? false : true);
    setCodeDistrict(item.codeDistrict);
    setExtraContent(item.extraContent);
    setForeignKey(item.foreignKey);
    setLatLng(item.lat + "," + item.lon);
    setElement(item);
    setOpenModal(true);
  }

  function createBody() {
    return {
      id: isEdit ? element?.id : 0,
      customerId: customerId,
      active: active,
      name: name.trimStart().trimEnd(),
      description: description.trimStart().trimEnd(),
      city: city.trimStart().trimEnd(),
      address: address.trimStart().trimEnd(),
      addressNumber: addressNumber,
      phone: phone ? phone.replace(/\D/g, "") : "",
      lat: 0,
      lon: 0,
      extraContent:
        !extraContent || stringFormat.isEmptyOrSpaces(extraContent)
          ? null
          : extraContent.trimStart().trimEnd(),
      foreignKey:
        !foreignKey || stringFormat.isEmptyOrSpaces(foreignKey)
          ? null
          : foreignKey.trimStart().trimEnd(),
      createIn: isEdit ? element?.createIn : new Date(),
      updateIn: new Date(),
      type: unit ? 2 : 0,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
      codeDistrict: unit ? 0 : codeDistrict,
      district: unit ? 1 : 0,
    };
  }

  async function post() {
    const resultValidation = validateFormFields();
    if (!resultValidation) {
      addToast({
        type: "warning",
        title: "Unidade",
        description:
          "Para criar uma unidade é necessário preencher os campos destacados.",
      });
      return;
    }

    if (!addressNumber || addressNumber < 1) {
      addToast({
        type: "warning",
        title: "Unidade",
        description: "Número deve ser maior que zero.",
      });
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(listUnit, name);

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      setFieldErrorName(true);
      return;
    }

    dispatch(showLoading());
    setLoading(true);

    const resultPost = await UnitService.post(createBody());
    if (resultPost.status === true) {
      addToast({
        type: "success",
        title: "Unidade cadastrada",
        description: "Unidade foi cadastrada com sucesso.",
      });
      clear();
      await onLoadList();
    } else {
      dispatch(hideLoading());
      if (!resultPost.status) {
        addToast({
          type: resultPost.severity,
          title: "Erro",
          description: resultPost.message,
        });
        return;
      }
      addToast({
        type: "error",
        title: "Falha ao criar a unidade.",
      });
      return;
    }

    setOpenModal(false);
    dispatch(hideLoading());
    setLoading(false);
  }

  async function put() {
    const resultValidation = validateFormFields();
    if (!resultValidation) {
      addToast({
        type: "warning",
        title: "Unidade",
        description:
          "Para atualizar uma unidade é necessário preencher os campos destacados.",
      });
      return;
    }

    if (!addressNumber || addressNumber < 1) {
      addToast({
        type: "warning",
        title: "Unidade",
        description: "Número deve ser maior que zero.",
      });
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(
      listUnit,
      name,
      element?.id
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      return;
    }

    const resultPut = await UnitService.put(createBody());
    setLoading(true);
    if (resultPut.status === true) {
      addToast({
        type: "success",
        title: "Unidade atualizada",
        description: "A unidade foi atualizada com sucesso.",
      });
      await clear();
      await onLoadList();
      dispatch(hideLoading());
    } else {
      if (!resultPut.status) {
        addToast({
          type: resultPut.severity,
          title: "Erro",
          description: resultPut.message,
        });
      } else {
        addToast({
          type: "warning",
          title: "Falha ao atualizar a unidade",
        });
      }
      dispatch(hideLoading());
    }
    setLoading(false);
    setOpenModal(false);
  }

  const filteredData =
    listUnit?.filter((item: any) =>
      item?.name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
    ) ?? [];

  return (
    <Container>
      <ContainerLeftMenu>
        <Administration />
      </ContainerLeftMenu>
      <AdminArea
        title={"Unidades"}
        InputSearchLabel="Buscar por"
        InputSearchOnChange={(text) => setSearch(text)}
        searchText={search}
        list={filteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={function (): unknown {
          throw new Error("Function not implemented.");
        }}
        onClick={(item: any) => onClick(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        onChangeSituation={(value: any) => setSituation(value)}
        permissionName={FORMS_ADMIN.PLACE}
        loading={loading}
      />

      <DrawerDefault
        title={isEdit ? "Editar unidade" : "Cadastrar unidade"}
        openDrawer={openModal}
        setOpenDrawer={() => clear()}
        onClose={() => clear()}
        onApply={isEdit ? put : post}
        children={
          <CreateEditUnit
            name={name}
            onChangeName={(e) => setName(e)}
            description={description}
            onChangeDescription={setDescription}
            city={city}
            onChangeCity={(e) => setCity(e)}
            address={address}
            onChangeAddress={(e) => setAddress(e)}
            addressNumber={addressNumber}
            onChangeAddressNumber={(e) => setAddressNumber(e)}
            phone={phone}
            onChangePhone={(e) => setPhone(e)}
            unit={unit}
            onChangeUnit={() => setUnit(!unit)}
            extraContent={extraContent}
            onChangeExtraContent={(e) => setExtraContent(e)}
            active={active}
            onChangeSwitch={() => setActive(!active)}
            latLng={latLng}
            onChangeLatLng={(e) => setLatLng(e)}
            foreignKey={foreignKey}
            onChangeForeignKey={(e) => setForeignKey(e)}
            setCodeDistrict={setCodeDistrict}
            codeDistrict={codeDistrict}
            fieldErrorName={fieldErrorName}
            fieldErrorDescription={fieldErrorDescription}
            fieldErrorCity={fieldErrorCity}
            fieldErrorAddress={fieldErrorAddress}
            fieldErrorAddressNumber={fieldErrorAddressNumber}
            fieldErrorPhone={fieldErrorPhone}
            fieldErrorForeignKey={fieldErrorForeignKey}
            fieldErrorExtraContent={fieldErrorExtraContent}
            fieldErrorCodeDistrict={fieldErrorCodeDistrict}
          />
        }
      />
    </Container>
  );
}
