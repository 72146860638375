/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  SET_ATTACHMENT_LIST,
  ADD_NEW_ATTACHMENT,
  ADD_ATTACHMENT_UPLOADED,
  CLEAN_ATTACHMENT_UPLOADS,
  EDIT_ATTACHMENT_FILE,
  CLOSE_MODAL_ATTACHMENT_FILE,
} from "../actions/actios.types";
import { IUpload } from "../interface";

const INITIAL_STATE: any = {
  uploadList: [] as Array<IUpload>,
  attachmentList: [],
  uploadedList: [] as Array<IUpload>,
  editItem: null,
  closeModal: false,
  openEditModal: false,
};

const attachment = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ADD_NEW_ATTACHMENT:
      return {
        ...state,
        uploadList: [...state.uploadList, action.attachment],
      };
    case SET_ATTACHMENT_LIST:
      return {
        ...state,
        attachmentList: action.attachmentList,
      };
    case ADD_ATTACHMENT_UPLOADED:
      return {
        ...state,
        uploadedList: [...state.uploadedList, action.attachment],
      };
    case CLEAN_ATTACHMENT_UPLOADS:
      return {
        ...state,
        uploadList: [],
        uploadedList: [],
      };
    case EDIT_ATTACHMENT_FILE:
      return {
        ...state,
        editItem: action.editFile,
        openEditModal: true,
      };
    case CLOSE_MODAL_ATTACHMENT_FILE:
      return {
        ...state,
        closeModal: action.closeModal,
        openEditModal: false,
        editItem: null,
      };
    default:
      return state;
  }
};

export default attachment;
