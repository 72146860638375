import React, { Fragment, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

import {
  Container,
  FooterContainer,
  ButtonContainer,
  ContainerTiny,
  OptionListContainer,
  ContainerItem,
  Circle,
  ItemTitle,
  ContainerTemplate,
  StatusContainer,
  ContainerStatus
} from "./styles";
import { Button } from "@kentarepo/kcf-assets";
import { ITranscription } from "./interface";

import { TranscriptionStatus } from "../utils";
import { editorInit } from "../../../../services/utils/editor.config";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { usePermissions } from "../../../../hooks/permission";
import { FORMS_HEARING } from "../../../../utils/entities/permission";
import { customerTemplateService } from "../../../../services/costumerTemplate";

const optionList = [
  { id: 1, value: "Manual", type: "manual" },
  { id: 2, value: "Automática", type: "automatico" },
];

const Transcription: React.FC<ITranscription> = ({
  buttonTitle,
  buttonFunction,
  disabledButton,
  status,
  ataRef,
  transcription,
  modelList,
  transcriptionSelect,
  setTranscriptionSelect,
  selectTemplate,
  setSelectTemplate,
  showModelInput,
  setShowModelInput,
  hearingId
}) => {
  const [tinyText, setTinyText] = useState("")
  const { export: exportDownloads } = usePermissions(FORMS_HEARING.HEARING_FILE);

  const selectedModel =
    transcription && transcription[0] && modelList.length
      ? modelList.find((x: any) => x?.id === transcription[0].customerTemplateId)
      : { name: "Selecione o modelo", template: "" };

  useEffect(() => {
    if (transcription && transcription.length) {
      setTinyText(selectTemplate ? selectTemplate.template : transcription[0].content);
    }
  }, [transcription]);

  useEffect(() => {
    (async () => {
      if (!(transcription && transcription.length && transcription[0] && transcription[0]?.content)) {
        if (selectTemplate && selectTemplate.template) {
          const { body } =
            await customerTemplateService.SetKeywords({
              hearingId: Number(hearingId),
              customerTemplateId:
                selectTemplate && selectTemplate ? selectTemplate?.id : 0,
            });
          setTinyText(body);
        }
      }
    })();
  }, [selectTemplate, hearingId]);


  return (
    <Container>
      <ContainerTiny showModelInput={showModelInput}>
        {status === 6 ? (
          <Fragment>
            {showModelInput && (
              <ContainerTemplate>
                <DropdownGlobal
                  labelName="Modelo"
                  options={modelList}
                  defaultValue={selectTemplate ? selectTemplate?.name : selectedModel?.name}
                  showDefaultValue
                  width="320px"
                  setSelected={setSelectTemplate}
                  wantAnObject
                />
              </ContainerTemplate>
            )}
            <Editor
              apiKey={import.meta.env.VITE_APP_TINY_KEY}
              onInit={(_, editor) => { (ataRef.current = editor), setShowModelInput(true) }}
              init={editorInit(exportDownloads, true)}
              initialValue={
                transcription && transcription[0]
                  ? transcription[0].textPlain
                  : ""
              }
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
              value={tinyText}
              onEditorChange={(e) => {
                setTinyText(e);
              }}
            />
          </Fragment>
        ) : (
          <StatusContainer>
            {(status === 0 || status !== null) && status >= 0 ? (
              <ContainerStatus>
                {TranscriptionStatus(status)}
              </ContainerStatus>
            ) : (
              <>
                <OptionListContainer>
                  {optionList.map((item) => (
                    <ContainerItem
                      key={item?.id}
                      onClick={() => {
                        setTranscriptionSelect(item.type === transcriptionSelect ? "" : item.type);
                        if (item.type === "automatico") setShowModelInput(true);
                        else setShowModelInput(false);
                      }}
                    >
                      <Circle selected={transcriptionSelect === item.type} />
                      <ItemTitle>{item.value}</ItemTitle>
                    </ContainerItem>
                  ))}
                </OptionListContainer>
                {showModelInput && (
                  <ContainerTemplate>
                    <DropdownGlobal
                      labelName="Modelo"
                      options={modelList}
                      defaultValue={selectTemplate ? selectTemplate?.name : selectedModel?.name}
                      showDefaultValue
                      width="320px"
                      setSelected={setSelectTemplate}
                      wantAnObject
                    />
                  </ContainerTemplate>
                )}
              </>
            )}
          </StatusContainer>
        )}
      </ContainerTiny>
      <FooterContainer>
        <ButtonContainer>
          <Button disabled={disabledButton} onClick={buttonFunction}>
            {buttonTitle}
          </Button>
        </ButtonContainer>
      </FooterContainer>
    </Container>
  );
};

export default Transcription;
