import React, { useEffect, useState } from "react";
import { ContainerFormOneLabel, ContainerFormOneLabelSwitch } from "./styles";
import { IFormOneLabel, IFormPost } from "./interface";

import { GeneralInput, Switch, SimpleToast } from "@kentarepo/kcf-assets";
import { stringFormat } from "@kentarepo/kcf-services";
import { post, put } from "./constants";
import { LogOperation } from "../../../utils/entities/logOperation";

type toastOption = "success" | "warning" | "error";

export const FormOneLabel: React.FC<IFormOneLabel> = ({
  displayLabel,
  customerId,
  modelFormBase,
  titleToast,
}) => {
  const editItem = modelFormBase.objectRetorned as IFormOneLabel;

  const [idAux, _setIdAux] = useState<number>(editItem ? editItem?.id : 0);
  const [text, setText] = useState<string>(editItem ? editItem?.name : "");
  const [activeAux, setActiveAux] = useState<boolean>(
    editItem ? editItem.active : true
  );

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  //Toast
  const [toastTrigger, setToastTrigger] = useState<number>(0);
  const [toastTitle, setToastTitle] = useState<string>(titleToast);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastType, setToastType] = useState<toastOption>("warning");

  useEffect(() => {
    setLoading(true);
    const handleSubmitForm = async () => {
      if (modelFormBase.onSubmitForm > 0) {
        try {
          let objectRetorned = null;
          if (textIsEmpty(text)) {
            setError(true);
            configureToast(
              `Para criar ${titleToast.toLocaleLowerCase()} é necessário preencher os campos destacados`,
              "warning"
            );

            modelFormBase.setOnSubmitForm(0);
            return;
          }

          if (listContainsText()) {
            setError(true);
            configureToast(`${titleToast.toLocaleLowerCase()} já existe`, "warning");

            modelFormBase.setOnSubmitForm(0);
            return;
          }

          if (modelFormBase.httpType === "post") {
            objectRetorned = await post(
              modelFormBase.api,
              handleCreateObjectToHttpType(),
              modelFormBase.endPoint
            );
            //objectRetorned = handleCreateObjectToHttpType() //para testes
          } else if (modelFormBase.httpType === "put") {
            objectRetorned = await put(
              modelFormBase.api,
              handleCreateObjectToHttpType(),
              modelFormBase.endPoint
            );
            //objectRetorned = handleCreateObjectToHttpType() //para testes
          }
          setError(false);
          modelFormBase.setObjectRetorned(objectRetorned);
        } catch (e) {
          configureToast("Ocorreu erro inesperado", "error");
        }
      }
    };

    setLoading(false);
    handleSubmitForm();
  }, [modelFormBase.onSubmitForm]);

  const handleCreateObjectToHttpType = (): IFormPost => ({
    id: idAux,
    active: activeAux,
    customerId: customerId,
    name: text,
    device: LogOperation.getDevice(),
    ip: LogOperation.getMachineIP(),
    logPoliceUnitId: LogOperation.getPoliceUnitId(),
    logUserId: LogOperation.getUserId(),
  });

  const textIsEmpty = (name: string) => {
    const isEmpty = stringFormat.isEmptyOrSpaces(name);
    if (isEmpty) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const listContainsText = () => {
    if (modelFormBase.list) {
      const contains = stringFormat.verifyExistNameInArray(
        modelFormBase.list,
        text,
        idAux
      );

      if (contains) {
        setError(true);
        return true;
      } else {
        setError(false);
        return false;
      }
    }
    return false;
  };

  const configureToast = (
    toastBodyMessage: string,
    toastOption: toastOption
  ) => {
    setToastTitle(titleToast);
    setToastBody(toastBodyMessage);
    setToastType(toastOption);
    setToastTrigger(toastTrigger + 1);
  };

  return (
    <>
      {!loading ? (
        <>
          <SimpleToast
            id="attendantRoleToastId"
            title={toastTitle}
            type={toastType}
            description={toastBody}
            handleCloseToast={() => {}}
            trigger={toastTrigger}
            directionHorizontal="Left"
          />
          <ContainerFormOneLabel>
            <ContainerFormOneLabelSwitch>
              <Switch
                onChange={() => setActiveAux(!activeAux)}
                checked={activeAux}
                label="Ativo"
              />
            </ContainerFormOneLabelSwitch>
            <GeneralInput
              id="title"
              label={displayLabel}
              name="formOneLabel"
              onChange={setText}
              value={text}
              error={error}
              required
              marginRight="0px"
              maxLength={90}
            />
          </ContainerFormOneLabel>
        </>
      ) : null}
    </>
  );
};
